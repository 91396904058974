@font-face {
  font-family: 'Superstar';
  src: url('./fonts/SuperstarOriginal-Regular.woff2') format('woff2'),
      url('./fonts/SuperstarOriginal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Upheaval';
  src: url('./fonts/UpheavalTT-BRK-.woff2') format('woff2'),
      url('./fonts/UpheavalTT-BRK-.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Retrogaming';
  src: url('./fonts/RetroGaming.woff2') format('woff2'),
      url('./fonts/RetroGaming.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Play';
  src: url('./fonts/Play-Regular.woff2') format('woff2'),
      url('./fonts/Play-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h3 {
  font-family: 'Superstar';
}

.font-superstar {
  font-family: 'Upheaval';
}

.font-upheaval {
  font-family: 'Upheaval';
}

.font-body {
  font-family: 'Play';
}

h1,h2,a {
  font-family: 'Upheaval';
}

.App {
  text-align: center;
  font-family: 'Retrogaming';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.button {
    @apply bg-wh-gold p-4 leading-5 md:px-6 text-lg font-bold text-white rounded transition;
}
.button.small {
    @apply bg-wh-gold p-2 px-6 text-lg font-bold text-white rounded transition;
}


.button.button__navy {
    @apply bg-wh-navy text-white;
}
.button.button__blue {
    @apply bg-wh-blue;
}
.button.button__light {
    @apply bg-wh-navy bg-opacity-10 text-wh-navy;
}
.button.button__outline {
  @apply bg-white border border-opacity-20 font-normal text-wh-font border-wh-navy;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  @apply w-[90%] max-w-screen-2xl;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cards__holder {
  flex-wrap: nowrap; /* [1] */
  overflow-x: auto; /* [2] */
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */ 
}

@screen md {
  .cards__holder {
    overflow-x: inherit; /* [2] */
  }
}


.cards__holder::-webkit-scrollbar {
    display: none; 
}

.card h2, h2.grad {
    background: -webkit-linear-gradient(45deg, theme('colors.tone-pink'), theme('colors.tone-orange'));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.w-video-wrapper {
  background: red;
}

.w-video-wrapper video {
  object-fit: cover !important;
  height: 102% !important;
}